.about-head{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
}
.about-desc{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    font-size: 4rem;
    font-family: cursive;
}