.create{
    display: flex;
    justify-content: center;
   width: 100%;
}
.form{
    border-radius: 50px;
   background-color: rgb(248, 184, 177);
    height: 450px;
    margin-top: 1rem;
width: 80%;
border-bottom: 2px solid rgb(97, 203, 245);
border-right: 2px solid skyblue;
border-left: 2px solid skyblue;
}
.title{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 40px;
    color: rgb(255, 252, 252);
    font-family:cursive;
    margin-top: 2rem;
    
}

.input-container{
    display: flex;
    justify-content: space-between;
    margin-top: 0.6rem;
    gap: 2rem;
    
}
.left{
    width: 50%;
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    font-family:poppins;
    font-weight: 700;
    padding: 15px;
    letter-spacing: 1px;
   
}
.right{
    letter-spacing: 1px;
    padding: 15px;
    font-family:poppins;
    font-weight: 700;
    width: 50%;
    display: flex;
    font-size: 1.1rem;
    flex-direction: column;
}
.textarea{
    text-transform: capitalize;
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 8px;
    margin: 4px;
    background-color: rgb(251, 244, 251);
    font-family: cursive;
}
.img-upload{
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 8px;
    margin: 2px;
    background-color: rgb(224, 224, 224);
    font-family: cursive;
    color: black;
    width: 15rem;
}
.btn{
    width: 100%;
    padding: 3px;
    outline: none;
    border: none;
    font-weight: 600;
    cursor: pointer;
      border-radius: 6px;
    color: rgb(255, 255, 255);
    background-color:rgb(124, 215, 252);
}
.btn:hover{
    background-color:rgb(83, 200, 246);
}
.btn:active{
    color: rgb(229, 82, 255);
    background-color:rgb(6, 184, 255);
}

.spinner{
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 13rem;
   
}
.data{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 5.5rem;
    width: 100%;
    height: 20rem;
  
    background-color: grey;
    color: white;
}
.back-btn{
    width: 10rem;
    height: 2rem;
    padding: 3px;
    outline: none;
    border: none;
    font-weight: 600;
    cursor: pointer;
      border-radius: 2px;
    color: rgb(255, 255, 255);
    background-color:rgb(124, 215, 252);
}
.back-btn:hover{
    background-color:rgb(83, 200, 246);
}
.back-btn:active{
    color: rgb(255, 0, 0);
    background-color:rgb(6, 184, 255);
}