.footer{
    width: 100%;
    height: 6rem;
  
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(147, 224, 255);
    width: 8rem;
    height: 2.5rem;
    border-radius:0.5rem;
}
.footer-img{
    width: 3rem;
    height: 2rem;
    cursor: pointer;
}