.detail-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 98%;
}
.detail-left{
    width: 45%;
    /* background-color: #a8e7ea; */
   
}
.detail-title{
    display: flex;
    align-items: center;
    justify-content: center;
}
.detail-right{
    width: 50%;
    margin-right: 1rem;
   margin-top: 1.5rem;
}
.detail-img{
position: relative;
width: 480px;
height: 300px;
margin-left: 2rem;
margin-top: 0rem;
/* background-color: #79df84; */
border-radius: 20px;
}
.detail-text{
    
    margin-left: 2rem;
    font-family: "Roboto";
    font-size: x-large;
    text-align: justify;
    color: rgb(255, 255, 255);
    margin-top: 4rem;
    font-weight: 500;
}
.funding-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
}
.detail-input{
    padding: 8px 15px;
    background-color: rgb(251, 251, 251);
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 8px;
    outline: none;
    font-size: large;
    width: 40%;
    height: 40px;
}
.invest-btn{
    display: flex;
    justify-content: center;
    width: 40%;
    padding: 15px;
    margin: 10px;
    color: white;
    background-color: #00b712;
    background-image: linear-gradient(180deg, #00b712 0%, #5aff15 80%);
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 8px;
    font-size: large;
    cursor: pointer;
}
.invest-btn:hover{
background-color: #00b712;    
background-image: linear-gradient(180deg, #068613 0%, #4ae009 80%);
}
.invest-btn:active{
    background-image: linear-gradient(180deg, #1c5a22 0%, #2d6c12 80%);
    color: rgb(0, 255, 0);
    }
.funding-data{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
   
}
.detail-fund-data{
    width: 50%;
    background-color:rgb(94, 97, 98);
    padding: 8px;
    border-radius: 2px;
    border: 1px solid  lightpink;
    text-align: center;
}
.required-funds{
    margin: 2px;
    font-size: large;
    padding: 0;
    font-family: "Poppins";
    font-size: normal;
}
.funded{
    height:280px;
  margin-top:10px;
  background-color: rgb(251, 175, 100);
}
.history-funds{
    height: 65%;
    overflow-y: auto;
}
.history-title{
    font-family: "Roboto";
    font-size: medium;
    font-weight: 600;
    text-transform: uppercase;
    padding: 4px;
    text-align: center;
    background-color: #4cd137;
}
.recent-data{
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
   background-color: #ee923c;
    padding: 4px 8px;
}